.contact-container {
    background-color: var(--nav-color);
    color: aliceblue;
    padding: 1rem;
}

.contact-data-links {
    display: flex;
    justify-content: space-evenly;
}

.contact-data-links a {
    color: var(--font-color);
}

    .contact-data-links a:hover {
        color: var(--light-color);   
    }

@media only screen and (max-width: 600px) {
    
    .contact-data-links {
        flex-direction: column;
    }
}