@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu&display=swap');

.App {
    font-family: 'Roboto', sans-serif;
    font-family: 'Ubuntu', sans-serif;
}

:root {
    --nav-color: hsl(210, 11%, 15%);
    --nav-color-hover: hsl(210, 11%, 45%);
    --nav-border-color: hsl(210, 11%, 45%);
    --light-color: hsl(209, 100%, 92%);
    --font-color: hsl(0, 0%, 100%);
    --font-color-hover: hsl(0, 0%, 40%);
    --hero-color: hsla(217, 64%, 44%, 0.774);
}
