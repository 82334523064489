@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    font-family: 'Roboto', sans-serif;
    font-family: 'Ubuntu', sans-serif;
}

:root {
    --nav-color: hsl(210, 11%, 15%);
    --nav-color-hover: hsl(210, 11%, 45%);
    --nav-border-color: hsl(210, 11%, 45%);
    --light-color: hsl(209, 100%, 92%);
    --font-color: hsl(0, 0%, 100%);
    --font-color-hover: hsl(0, 0%, 40%);
    --hero-color: hsla(217, 64%, 44%, 0.774);
}

.navigation__container {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--nav-color);
    color: white;
    z-index: 2;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--font-color) !important;
}

.navbar-light .navbar-brand {
    color: var(--font-color) !important;
}

.navbar-light .navbar-toggler-icon {
    -webkit-filter: invert(1);
            filter: invert(1);
}

.links a:hover {
    background-color: var(--font-color-hover);
}
.hero-Text {
    display: flex;
    position: absolute;
    top: 50%;
    left: 40%;
    right: 40%;
    color: var(--font-color);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.tsparticles-canvas-el {
    background-color: var(--hero-color);
    width: 100%;
    max-height: 100vh;
}
.about-container {
    margin: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.about-text {
    max-width: 600px;
}

#my-image {
    max-width: 500px;
    max-height: 500px;
    object-fit: scale-down;
    border-radius: 50%;
    border: 1px solid var(--hero-color);
    margin: 1rem;
}

/* Media Queries */

@media only screen and (max-width: 900px) {

    #my-image {
        max-width: 350px;
        max-height: 350px;
    }

    .about-container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 700px) {

    #my-image {
        max-width: 250px;
        max-height: 250px;
    }

    .about-text {       
        max-width: 300px;
    }    
}
.tech-container {
    background-color: var(--light-color);
    color: var(--nav-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
}

.tech-cards-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1100px;
    margin: 1rem 0;
}

.tech-card {
    width: 15rem;
    height: 10rem;
    background-color: var(--font-color);
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border-bottom: 5px solid var(--nav-color);
    transition: all 0.1s;
}

.tech-icons {
    height: 70px;
    background-color: var(--light-color);
    border-radius: 50%;
}

    .tech-icons:hover {
        background-color: var(--light-color);
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
.portfolio {
    background-color: aqua;
  }
  
  .portfolio-container h1, 
  .portfolio-container p {
    text-align: center;
    margin-top: 3rem;
  }
  
  #portfolio-card-container {
    width: 50%;
    padding: 10px;
    border: none;
  }

  .portfolio-image {
    height: 550px;
    border: 1.5mm ridge var(--hero-color);
  }
  
  .porfolio-image:hover {
    cursor: pointer;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
  }
  
  .portfolio-click-info {
    position: absolute;
    top: 50%;  
    left: 50%; 
    -webkit-transform: translate(-50%, -50%); 
            transform: translate(-50%, -50%);
    color: var(--font-color);
    display: none;
  }
  
  .porfolio-image:hover + .portfolio-click-info {
    display: block;
  }
  
  .modal-backdrop.show {
    opacity: .1 !important;
  }
  
  #portfolio-modal-link {
    padding: 0 0 1rem 1rem
  }
  
  #portfolio-modal-link:link {
    text-decoration: none;
  }
  
  @media only screen and (max-width: 600px){
      #portfolio-card-container {
      width: 100%;
      padding: 0.5rem 2.5rem;
      border: none;
    }
  }
.contact-container {
    background-color: var(--nav-color);
    color: aliceblue;
    padding: 1rem;
}

.contact-data-links {
    display: flex;
    justify-content: space-evenly;
}

.contact-data-links a {
    color: var(--font-color);
}

    .contact-data-links a:hover {
        color: var(--light-color);   
    }

@media only screen and (max-width: 600px) {
    
    .contact-data-links {
        flex-direction: column;
    }
}
