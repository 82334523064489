.about-container {
    margin: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.about-text {
    max-width: 600px;
}

#my-image {
    max-width: 500px;
    max-height: 500px;
    object-fit: scale-down;
    border-radius: 50%;
    border: 1px solid var(--hero-color);
    margin: 1rem;
}

/* Media Queries */

@media only screen and (max-width: 900px) {

    #my-image {
        max-width: 350px;
        max-height: 350px;
    }

    .about-container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 700px) {

    #my-image {
        max-width: 250px;
        max-height: 250px;
    }

    .about-text {       
        max-width: 300px;
    }    
}