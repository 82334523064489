.hero-Text {
    display: flex;
    position: absolute;
    top: 50%;
    left: 40%;
    right: 40%;
    color: var(--font-color);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}